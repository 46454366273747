import '../styles/globals.scss'
import '../styles/blackfriday.css'
import '../styles/blog.scss'
import '../styles/pricing.css'
import '../styles/landing.scss'
import '../styles/contact.scss'
import '../styles/affiliate.scss'
import '../styles/share.scss'
import '../styles/downloads.scss'
import '../styles/features.scss'
import '../styles/tools.scss'

import { useRouter } from 'next/router'
import { useEffect } from 'react'

//import { useTracking } from '../js/useTracking'
/*
const myFont = localFont({ src: [
  {path: './fonts/Gilroy-SemiBold/font.woff', style: 'normal', weight: '600'},
  {path: './fonts/Gilroy-Medium/font.woff', style: 'normal', weight: '500'}, 
  {path: './fonts/Gilroy-Bold/font.woff', style: 'normal', weight: 'bold'}, 
  {path: './fonts/Gilroy-Regular/font.woff', style: 'normal', weight: '400'}, 
]})
*/

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  //const { initilize } = useTracking()
  const handleRouteChange = (url) => {
    if (typeof window !== 'undefined') {
      window?.gtag('config', 'UA-166939348-2', {
        page_path: url,
      })
    }
  }
  /* useEffect(() => {
    initilize()
  }, [initilize])*/

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return <Component {...pageProps} />
}

export default MyApp
